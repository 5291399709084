import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The page might not exist anymore, or it might have moved.`}</p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Go to home`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      